import React, { useState, useContext } from "react";
import { Link } from "gatsby";
import BIMLOGO from "../../assets/svgs/Logo.svg";
import { FaLinkedinIn } from "react-icons/fa";
import Data from "./data.yml";

let viewerUrl = process.env.GATSBY_VIEWER_URL;

const FooterCard = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="py-10 bg-black md:py-16">
      <div className="tw-container">
        <div className="footer_grid grid items-start justify-between gap-8 pb-8">
          {/* Row 1 Column 1 */}
          <div className="lg:w-[384px]">
            <BIMLOGO className="h-14 w-auto" />
            <p className="my-3 text-base text-gray-400 leading-6 font-normal">
              The World's Leading BIM Services Provider
            </p>
            <a
              href="https://www.linkedin.com/company/bim-engineering-construction-sg/"
              className="text-gray-400 transition-all duration-300 ease-in-out hover:text-main-primary hover:opacity-80"
            >
              <FaLinkedinIn className="w-8 h-8 text-gray-300 border-solid border-gray-300 rounded-md p-[2px]" />
            </a>
          </div>

          {/* Column 2 */}
          <div className="grid">
            <p className="text-white text-lg leading-6 font-semibold tracking-wider uppercase mb-3 md:mb-4">
              All Available Software
            </p>
            <div className="footer_soft_grid grid ">
              {Data.footer.software.map((item, i: number) => (
                <Link
                  to={item.link}
                  className="text-base leading-6 text-gray-300"
                >
                  {item.label}
                </Link>
              ))}
            </div>
          </div>

          {/* Column 3 */}
          <div className="grid">
            <p className="text-white text-lg leading-6 font-semibold tracking-wider uppercase mb-3 md:mb-4">
              Company
            </p>
            <div className="grid gap-4">
              {Data.footer.company.map((item, i: number) => (
                <Link
                  to={item.link}
                  className="text-base leading-6 text-gray-300"
                >
                  {item.label}
                </Link>
              ))}
            </div>
          </div>
        </div>

        {/* Row 2 */}
        {/* <form className="flex flex-wrap justify-between items-center gap-6 border-0 py-8 border-solid border-t border-gray-400">
          <div>
            <h3 className="text-sm leading-5 font-semibold tracking-wider uppercase text-gray-400">
              Subscribe to our newsletter
            </h3>
            <p className="text-base leading-6 font-normal text-gray-400">
              The latest news, articles, and resources, sent to your
              inbox weekly.
            </p>
          </div>
          <div className="flex gap-3 flex-wrap">
            <input
              type="email"
              placeholder="Enter your email"
              required
              className="h-fit max-w-[282px] py-[9px] px-[13px] rounded-md"
            />
            <input
              type="submit"
              value="Subscribe"
              className="h-fit py-[9px] px-[17px] rounded-md bg-[#FBDA05]"
            />
          </div>
        </form> */}

        <div className="pt-8 border-0 pb-16 border-solid border-t border-gray-400">
          <p className="text-base leading-6 font-normal text-gray-400 md:text-base">
            ©{year} Bimeco, Incorporated  All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterCard;
